import React from 'react'
import Title from './Title';

const CloudControlUnitHeader = ({ title, description }) => {
  return (
    <Title title={title} description={description} />
  )
}

export default CloudControlUnitHeader;
