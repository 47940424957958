import React from 'react'

const CloudControlUnitSectionItem = ({ title, description, icon }) => {
  return (
    <div className="relative">
      <dt>
        <div className="absolute p-2 flex items-center justify-center h-12 w-12 rounded-md bg-amber-500 text-white">
          {icon}
        </div>
        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{title}</p>
      </dt>
      <dd className="mt-2 ml-16 text-base text-gray-500">{description}</dd>
    </div>
  )
}

export default CloudControlUnitSectionItem;
