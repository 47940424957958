import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';
import { useImageForData } from "../../hooks/useAllImages";

export const CloudControlUnitSection = ({title, description, children, imagePath, imageLeft = false}) => {
  return (
    <div className=" bg-white overflow-hidden ">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-16">
          <div className={"relative " + (imageLeft ? 'order-1' : '')}>
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {title}
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              {description}
            </p>

            <dl className="mt-10 space-y-10">
              {children}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <GatsbyImage
              image={useImageForData(imagePath)}
              alt={title}
              className="rounded-xl"
              imgClassName="rounded-xl"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CloudControlUnitSection;
