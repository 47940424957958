import * as React from "react";
import PageTitle from "../components/elements/PageTitle";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";
import CloudControlUnitSection from "../components/sections/CloudControlUnitSection";
import CloudControlUnitHeader from "../components/elements/CloudControlUnitHeader";
import CloudControlUnitSectionItem from "../components/elements/CloudControlUnitSectionItem";
import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline'
import CloudControlUnitFooter from "../components/sections/CloudControlUnitFooter";

const FiscalizationApiPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Cloud control unit"}
      description={undefined}
    />
    <Header />
    <CloudControlUnitHeader title={'CCU Integation Steps'} description={'Securely managing and recording fiscal data for cash registers in compliance with regulatory requirements.'} />
    <CloudControlUnitSection 
      imagePath={"step_1.jpg"}
      description={'Comprehensive API solution designed to seamlessly integrate with your POS software and enhance its capabilities.'}
      title={'Unlock the Potential of Your POS Software with CCU-Service Integration'} 
    >
      <CloudControlUnitSectionItem 
        title={'Step 1: Create Your Developer Account'} 
        description={'Getting started with CCU-Service is easy. Simply sign up for a developer account, and our team will guide you through the process. Provide us with essential information about your company, software, and integration preferences, and we will take care of the rest. Your developer account gives you access to a wealth of resources and support to kickstart your integration journey.'} 
        icon={<AnnotationIcon />} 
      />
    </CloudControlUnitSection>

    <CloudControlUnitSection 
      imagePath={"step_2.jpg"}
      description={''}
      title={''} 
      imageLeft
    >
      <CloudControlUnitSectionItem 
        title={'Step 2: Explore the Test Environment'} 
        description={'Once your developer account is set up, you can dive into the test environment to explore the full potential of CCU-Service. Test demos, experiment with integration options, and access comprehensive API documentation and Postman collections through our dedicated portal at api.srv4pos.com. With our intuitive test environment, you can ensure seamless integration and optimal performance before going live.'} 
        icon={<GlobeAltIcon />} 
      />
    </CloudControlUnitSection>

    <CloudControlUnitSection 
      imagePath={"step_3.jpg"}
      description={''}
      title={''} 
    >
      <CloudControlUnitSectionItem 
        title={'Step 3: Streamline Tax Agency Registration'} 
        description={'Navigating the regulatory landscape can be daunting, but with CCU-Service, we have got you covered. Our step-by-step guide simplifies the process of registering your software with the Tax Agency. From filling out cash register declarations to conducting software testing and submitting manufacturers declarations, we provide clear instructions and support every step of the way. With CCU-Service, compliance is effortless.'} 
        icon={<LightningBoltIcon />} 
      />
    </CloudControlUnitSection>

    <CloudControlUnitSection 
      imagePath={"step_4.jpg"}
      description={''}
      title={''} 
      imageLeft
    >
      <CloudControlUnitSectionItem 
        title={'Step 4: Power Your Production Environment'} 
        description={'With your software registered and compliant, you are ready to unleash the full power of CCU-Service in a real-world environment. Keep your organization and software information up-to-date, and rest assured knowing that our dedicated support team is always available to assist you. Whether you need technical assistance or guidance on best practices, we are here to help you succeed.'} 
        icon={<ScaleIcon />} 
      />
    </CloudControlUnitSection>
 
    <CloudControlUnitFooter 
      title=""
      subtitle="Start using CCU today."
      description="Contact us today to optimize your POS performance and seamlessly integrate your opertations with our CCU service. "
    />
    <Footer />
  </div>
);

export default FiscalizationApiPage;
